import React from 'react';
import '../styles/newhero.css';
import img01 from '../assets/pic6.webp'
import img02 from '../assets/pic1.webp'
import img03 from '../assets/pic2.webp'

function Hero() {
  return (
    <div className="hero-container">
      <div className="left-content col-md-1" ></div>
      <div className="left-content col-md-5" >
        <div className="description">
          <h1 className="title">
            <span className="gradient-text">IT Consulting & Services</span> 
          </h1>
          <h2>Grow with us !</h2>
          <p className="paragraph">
          Your excellence partner for the green, clean, and sustainable IT solution; seamless sourcing of talent; and impactful training. 
          </p>
          <button type="submit" className="btn" aria-label="submit"> 
            <a href="https://docs.google.com/forms/d/1bq7Ob66xEwd97ngGxBATcH1qzsuG-FPD4K0hKil0YSE/edit?pli=1" target="_blank" style={{color:'white'}}> 
              Contact →
            </a>
          </button>
        </div>
      </div>
      <div className="right-content col-md-5" >
        <div className="relative-container"></div>
        <div className="relative flex justify-center items-center" >
          <svg className="mx-auto max-w-md max-h-md lg:max-w-full lg:min-w-[445px] lg:max-h-full lg:min-h-[445px] text-gray-300" viewBox="0 0 550 550" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: '-50px' }}>
            <circle cx="275" cy="275" r="220.5" stroke="currentColor" strokeOpacity="0.2" />
            <circle cx="275" cy="275" r="180" stroke="currentColor" strokeOpacity="0.2" />
            <circle cx="275" cy="275" r="150" stroke="currentColor" strokeOpacity="0.2" />
          </svg>
          
          <div className="circle w-full h-full max-w-md max-h-md lg:max-w-full lg:min-w-[445px] lg:max-h-full lg:min-h-[445px]" style={{ "--total": 12 }}></div>
          <div className="card" id="card1" style={{ position: 'absolute', top: '60%', left: '30%', transform: 'translate(-50%, -50%)' }}>
            <img src={img01} alt="Card 1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div className="card" id="card2" style={{ position: 'absolute', top: '70%', left: '60%', transform: 'translate(-50%, -50%)' }}>
            <img src={img02} alt="Card 2" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div className="card" id="card3" style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <img src={img03} alt="Card 3" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
        </div>
      </div>
      <div className="left-content col-md-1" ></div>
    </div>
  );
}

export default Hero;
