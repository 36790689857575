import React from 'react';
import '../styles/terms.css'


const TermsConditionsPage = () => {
  return (
    <div className="container mt-5 mb-5" style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h2 style={{ textAlign: 'center' }}>Terms & Conditions</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '800px' }}>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>
            <p>Acceptance of Terms  : 
              <span> By clicking 'Enquire Now' or by engaging with our services, you agree to be bound by these Terms & Conditions.</span>
            </p>
          </li>
          <li>
            <p>Confidentiality: 
              <span> We will maintain the confidentiality of any proprietary information shared with us during the course of our engagement.</span>
            </p>
          </li>
          <li>
            <p>Intellectual Property:
              <span> Any materials or intellectual property provided as part of our services remain the property of Cohesion unless otherwise specified in writing.</span>
            </p>
          </li>
          <li>
            <p>Indemnification:<span> You agree to indemnify and hold us harmless from any claims, losses, or damages arising out of your use of our services.</span>
            </p>
          </li>
          <li>
            <p>Changes to Terms:<span> We reserve the right to update or modify these Terms & Conditions at any time without prior notice. Continued use of our services after any such changes shall constitute your consent to such changes.</span>
          </p>
          </li>
          <li>
            <p>Contact Information:<span> For any questions or concerns regarding these Terms & Conditions, please contact us at [Your Contact Information].</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermsConditionsPage;
