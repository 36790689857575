import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/career.css'; // Import your CSS styles

class TrainingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCourse: '', // To store the selected course from dropdown
    };
  }

  handleCourseChange = (event) => {
    this.setState({ selectedCourse: event.target.value });
  };

  // Define handleSyllabusDownload within the class
  handleSyllabusDownload = (syllabusUrl) => {
    // Implement your logic to handle the download here!
    // You can use libraries like FileSaver.js for complex downloads
    // or simply redirect the user to the syllabus URL.
    // Here's an example redirect using window.location:
    window.location.href = syllabusUrl;
    console.log("Downloading syllabus from:", syllabusUrl); // Optional logging
  };

  render() {
    // Sample course data
    const courses = [
      {
        title: 'Full Stack Development',
        skills: 'JavaScript, React, Node.js',
        location: 'San Francisco, CA',
        hasSyllabus: true,
        syllabusUrl: 'https://example.com/fullstack-syllabus.pdf',
      },
      {
        title: 'Web Development',
        skills: 'HTML, CSS, JavaScript, React',
        location: 'New York, NY',
        hasSyllabus: false,
      },
      {
        title: 'Data Science',
        skills: 'Python, Machine Learning, Data Analysis',
        location: 'Chicago, IL',
        hasSyllabus: true,
        syllabusUrl: 'https://example.com/datascience-syllabus.pdf',
      },
    ];

    const SyllabusLink = ({ url, text = 'Download Syllabus' }) => {
      return (
        <a href={url} download>{text}</a>
      );
    };

    return (
      <div className="training-container">
        <div className="training-banner">
          <div className="left-section banner-section">
            <h3>Professional Training</h3>
            <p>
              Elevate your skills and accelerate your career with our professional training programs. Whether you're a beginner or an experienced professional, we offer courses tailored to your needs. Our industry experts will guide you through practical projects, ensuring you gain hands-on experience in the latest technologies. Join us and unlock your potential today.
            </p>
            <div>
              <select className="select-dropdown" value={this.state.selectedRole} onChange={this.handleRoleChange}>
                <option value="">Select Role</option>
                {courses.map((courses, index) => (
                  <option key={index} value={courses.title}>
                    {courses.title}
                  </option>
                ))}
              </select>
              <Link to="/contact" className="apply-button btn btn-gradient-border btn-glow callback">
      Apply
    </Link>
            </div>
          </div>
        </div>
        <div className="courses-section">
          <h3>Available Courses:</h3>
          <table className="excel-like-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Skills</th>
                <th>Location</th>
                <th>Syllabus</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={index}>
                  <td>{course.title}</td>
                  <td>{course.skills}</td>
                  <td>{course.location}</td>
                  <td>
                    {course.hasSyllabus ? (
                      <SyllabusLink url={course.syllabusUrl} text="Download Syllabus" />
                    ) : (
                      <span>No syllabus available</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TrainingComponent;
