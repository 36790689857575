

function Joinus() {
  return (
    <div className="bg-transparent flex flex-col justify-center relative overflow-hidden sm:py-12 mt-16 mb-16">
      <div className="w-full max-w-5xl mx-auto px-4 sm:px-0"> {/* Adjusted max width */}
        <div className="relative group">
          <div className="absolute -inset-1 rounded-lg" style={{ backgroundColor: '#1e1b4b' }}></div>
          <div className="relative px-7 py-1 ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6" >
            <svg className="w-8 h-8 text-purple-600" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"></path>
            </svg>
            <div className="space-y-2">
              <h4 style={{fontSize: 'calc(1rem + 0.5vw)'}}>Passionate about empowering others? </h4>
              <p className="text" style={{color:'#e0ffff'}}>Become an instructor, mentor or provide fellowship and help learners upskill.</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSePtWrUUjf8WqOPo5V3pzUcNA5U_LeDkjDvMqVa6hhIQzvtkg/viewform" target="_blank" style={{color:'#a78bfa'}}>Join Us →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joinus;
