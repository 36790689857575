import React from 'react'
import '../styles/wwr.css'

function wwr() {
  return (
    <div className="card-container" id='wwr'>
      <div className="wwr-card" id='card1'>
        <h2>Card 1</h2>
        <p>This is the content of card 1.</p>
      </div>
      <div className="wwr-card" id='card2'>
        <h2>Card 2</h2>
        <p>This is the content of card 2.</p>
      </div>
    </div>
  );
};

export default wwr