import React from 'react';
import logo from '../assets/kalpaz-high-resolution-logo-transparent (1).png'
import '../styles/footer.css';

const links = () => {
  return (
    <links style={{padding: '2rem 0' }} >
      <div className="footer-container">
        <div className="row justify-content-center">
          {/* Logo Section */}
          <div className="footer-section col-md-6 col-lg-3 text-center">
            <img src={logo} 
             style={{
              width: '120px',
              height: 'auto',
              cursor: 'pointer'
            }}/>
          <p style={{ textAlign: 'left', marginTop:'10px',fontSize: '17px' }}>With a focus on sustainability and ethics, we drive innovation with integrity, ensuring a greener future for tech.</p>
          </div>
          {/* Assets Section */}
          <div className="footer-section col-md-6 col-lg-3 ">
            <h5>Choose interest</h5>
            <ul>
              <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLSdSiANrefR7DGwVHmrGD7ExrApyTs8L22pmng7DATcEUdjWtg/viewform" target="_blank">Hire from us →</a></li>
              <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLSdfSfsmHMNAzr2hWiMRctPAJIMfT_AwMvHV5uXQJUOhpmeZ7g/viewform" target="_blank">Get a free consultation →</a></li>
              <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLSePtWrUUjf8WqOPo5V3pzUcNA5U_LeDkjDvMqVa6hhIQzvtkg/viewform" target="_blank">Join as a trainer →</a></li>
              <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLSePtWrUUjf8WqOPo5V3pzUcNA5U_LeDkjDvMqVa6hhIQzvtkg/viewform" target="_blank">Become a mentor →</a></li>
              <li> <a href="https://docs.google.com/forms/d/e/1FAIpQLScRKp0eRe_qXHfgRQyRjvTSe96TQnEZfzfEmPPcjv5lXNdIGw/viewform" target="_blank">Upskill your team →</a></li>
            </ul>
          </div>
          
          {/* Links Section */}
          <div className="footer-section col-md-6 col-lg-3 ">
            <h5>Quick Links</h5>
            <div className="footer-links">
              <ul style={{ textAlign: 'left' }}>
              <li><a href="https://docs.google.com/forms/d/e/1FAIpQLScRKp0eRe_qXHfgRQyRjvTSe96TQnEZfzfEmPPcjv5lXNdIGw/viewform" target="_blank">Contact </a></li>
              <li><a href="/jobhub">Job Hub</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
      <p>© {new Date().getFullYear()} <span style={{
  background: 'rgb(0,212,255)',
  background: 'linear-gradient(90deg, rgba(0,212,255,1) 35%, rgba(83,213,166,1) 100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
  fontWeight: '500',
}}>Kalpaz</span> All rights reserved.</p>



      <p> 📩 enquiry@kalpaz.com</p>
    </div>
    </links>
  );
};

export default links;
