import React from 'react';
import '../styles/contact.css';
import $ from 'jquery';

function submitToAPI() {
    var URL = "https://ezmc2l57p0.execute-api.us-east-1.amazonaws.com/beta";

    var Namere = /[A-Za-z]{1}[A-Za-z]/;
            if (!Namere.test($("#name-input").val())) {
                         alert ("Name can not less than 2 char");
                return;
            }

    var mobilere = /[0-9]{10}/;
    if (!mobilere.test($("#phone-input").val())) {
        alert("Please enter a valid mobile number");
        return;
    }

    if ($("#email-input").val() === "") {
        alert("Please enter your email id");
        return;
    }

    var reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reeamil.test($("#email-input").val())) {
        alert("Please enter a valid email address");
        return;
    }

    var name = $("#name-input").val();
    var phone = $("#phone-input").val();
    var email = $("#email-input").val();
    var desc = $("#description-input").val();
    var data = {
        name: name,
        phone: phone,
        email: email,
        desc: desc
    };

    $.ajax({
      type: "POST",
      url : "https://ezmc2l57p0.execute-api.us-east-1.amazonaws.com/beta",
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(data),

      success: function () {
        // clear form and show a success message
        alert("Successful");
        document.getElementById("contact-form").reset();
    },
    error: function () {
        // show an error message
        alert("Unsuccessful");
         }});
     }

function Contact() {
    return (
        <div className="section1">
            <div className='Banner1'>
                <h2>How can we help?</h2>
                <p>Please choose from the options below. If needed, complete our contact form.</p>
                </div>
      <div className="options">
        <label htmlFor="consultation">
          <input type="checkbox" id="consultation" name="consultation" />
          Get a free consultation
        </label>

        <label htmlFor="recruitment">
          <input type="checkbox" id="recruitment" name="recruitment" />
          Recruitment & Staffing
        </label>

        <label htmlFor="training">
          <input type="checkbox" id="training" name="training" />
          Training Enquiry
        </label>

        <label htmlFor="resume">
          <input type="checkbox" id="resume" name="resume" />
          Submit your resume
        </label>
      </div>

            <div className="mobile-screen">
                <div>
                    <form id="contact-form" method="post">
                        <h4>Name:</h4>
                        <input type="text" id="name-input" placeholder="Enter name here…" className="form-control" /><br />
                        <h4>Phone:</h4>
                        <input type="tel" id="phone-input" placeholder="Enter phone number" className="form-control" /><br />
                        <h4>Email:</h4>
                        <input type="email" id="email-input" placeholder="Enter email here…" className="form-control" /><br />
                        <h4>How can we help you?</h4>
                        <textarea id="description-input" rows="3" placeholder="Enter your message…" className="form-control"></textarea><br />
                        {/* You may add Captcha here */}
                        <button type="button" onClick={submitToAPI} className="btn btn-lg" style={{ marginTop: '20px' }}>Submit</button>
                    </form>
                </div>
            </div>
            <div style={{ paddingBottom: '80px' }}></div>
        </div>
    );
}

export default Contact;
