import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import logo from '../assets/kalpaz-favicon-color.png';
import '../styles/recruiter.css';

const Recruiter = () => {
  return (
    <div className="container" style={{ marginTop: '80px' }}>
      <div className="row">
        {/* First Section (two-thirds of the page) */}
        <div className="col-md-8">
  <div className="overview card" style={{ position: 'relative', marginBottom: '20px' }}>
    <div className="card-body">
      <svg width="100" height="100" style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <rect width="60" height="60" x="10" y="10" rx="10" ry="10" stroke="rgba(255, 255, 255, .25)" strokeWidth="2" fill="none" />
        <image href={logo} x="10" y="10" width="60" height="60" alt="Company Logo"/>
      </svg>
      <h5 className="card-title">Technical Recruiter</h5>
      <div className="row">
        <div className="col-auto">
          <p className="card-text">
            <i className="fas fa-briefcase" style={{ color: 'gray' }}></i> 0-3 years
          </p>
        </div>
                <div className="col-auto">
                  <p className="card-text">
                    <span role="img" aria-label="Package">₹</span> Not disclosed
                  </p>
                </div>
              </div>
              <div>
                <p className="card-text">
                  <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                </p>
                {/* Horizontal Line */}
                <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
                {/* Button */}
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary">Apply →</button>
                </div>
              </div>
            </div>
          </div>

          {/* Job Description Card */}
          <div className="job-description card">
            <div className="card-body">
              <h5 className="card-title">Job Description</h5>
              <p className="card-text">
                <strong>Technical Recruiter | 0-3 years | Remote</strong>
              </p>
              <h6>Responsibilities:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Source candidates through online databases, social media, and job boards; attend networking events.</li>
                <li>Screen the candidates as per the given requirements.</li>
                <li>Schedule and conduct the interviews; provide feedback to hiring managers.</li>
                <li>Enhance candidate experience through the recruitment process by maintaining communication.</li>
                <li>Describes the role, company culture, and discusses growth opportunities with applicants.</li>
                <li>Works with hiring managers to understand needs of the job, team dynamics, and technical skills in roles.</li>
                <li>Supports defining the job descriptions and requirements of roles.</li>
                <li>The candidate will track and analyze recruitment metrics such as time-to-hire, cost-per-hire, source of hire, etc. in detail for continuous improvement in the recruitment process.</li>
                <li>Keep the HR and leadership teams regularly updated on open positions and their corresponding recruitment strategies.</li>
                <li>Represent the company at professional events, job fairs, and on social media to brand the employer.</li>
                <li>Design and implement strategies to promote the company culture and values to potential candidates.</li>
              </ul>
              <h6>Role Details:</h6>
              <p>
                <strong>Role:</strong> Technical Recruiter<br />
                <strong>Industry Type:</strong> IT Service and Consulting<br />
                <strong>Department:</strong> Human Resources<br />
                <strong>Employment Type:</strong> Full Time, Permanent<br />
                <strong>Role Category:</strong> Recruitment and Hiring
              </p>
              <h6>Education:</h6>
              <p>
                <strong>UG:</strong> Any Graduate<br />
                <strong>PG:</strong> Any Postgraduate
              </p>
              <h6>Key Skills:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Excellent verbal and written communication skills</li>
                <li>Tech Savvy</li>
                <li>Excel Skills</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Second Section (one-third of the page) */}
        <div className="col-md-4 sidebar">
          <h5>Jobs you might be interested in</h5>
          
          {/* Full Stack Developer Card (Clickable) */}
          <Link to="/Fullstack" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Full Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Frontend Developer Card (Clickable) */}
          <Link to="/Frontend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Frontend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Backend Developer Card (Clickable) */}
          <Link to="/Backend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Backend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* MERN Stack Developer Card (Clickable) */}
          <Link to="/mern" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">MERN Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* DevOps Engineer Card (Clickable) */}
          <Link to="/Devops" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">DevOps Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Cloud Engineer Card (Clickable) */}
          <Link to="/Cloud" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Cloud Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Project Manager Card (Clickable) */}
          <Link to="/projectmanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Project Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/Socialmediamanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Social Media Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/recruiter" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Technical Recruiter</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
        </div>
        </div>
    </div>
  );
};

export default Recruiter;
