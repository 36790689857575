import React from 'react';
import '../styles/team.css';
import Team1 from '../assets/Vishwajeet.png'
import logo1 from '../assets/Bosch-Logo-1.png'
import Team2 from '../assets/Rohan.jpg'
import logo2 from '../assets/Capgemini-Logo1.png'
import Team3 from '../assets/Deepak.jpeg'
import logo3 from '../assets/deloitte_logo.jpg'
import Team4 from '../assets/Leo.jpeg'
import logo4 from '../assets/WafaiCloud_logo.jpg'

function Team() {
  return (
    <div className="team-container">
      <div className="heading" style={{marginBottom: '40px'}}>
        <h2 style={{color:'#a78bfa', textAlign:'center'}} className="heading__title">The <span style={{color:'#00fa9a'}}>Tech Expert's !</span> </h2>
      </div>
      <div className="team">
        <div className="card" id="team-card">
          <div className="card-img" id="profile-img">
            <img src={Team1} alt="Team member" />
          </div>
          <div className="desc" id="profile-desc">
            <h5 className="mentor-name">Vishwajeet Nayak</h5>
            <h5 className="mentor-skills">MS D365 consultant</h5>
            <img 
                src={logo1} 
                style={{ width: '80px', height: '50px' }} alt="Team member"/>
          </div>
        </div>

        <div className="card" id="team-card">
          <div className="card-img" id="profile-img">
          <img src={Team2} alt="Team member" />
          </div>
          <div className="desc" id="profile-desc">
            <h5 className="mentor-name">Rohan Anand</h5>
            <h5 className="mentor-skills">Data Analyst</h5>
            <img 
                src={logo2} 
                style={{ width: '80px', height: '20px', marginTop:'15px' }} alt="Team member"/>
          </div>
        </div>

        <div className="card" id="team-card">
          <div className="card-img" id="profile-img">
          <img src={Team3} alt="Team member" />
          </div>
          <div className="desc" id="profile-desc">
            <h5 className="mentor-name">Deepak Mishra</h5>
            <h5 className="mentor-skills">Cloud Consultant</h5>
            <img 
                src={logo3} 
                style={{ width: '60px', height: '30px', marginTop:'10px' }} alt="Team member"/>
          </div>
        </div>

        <div className="card" id="team-card">
          <div className="card-img" id="profile-img">
          <img src={Team4} alt="Team member" />
          </div>
          <div className="desc" id="profile-desc">
            <h5 className="mentor-name">Leo Joy</h5>
            <h5 className="mentor-skills">Cybersecurity Expert</h5>
            <img 
                src={logo4} 
                style={{ width: '50px', height: '30px', marginTop:'10px' }} alt="Team member"/>
          </div>
        </div>
        <div className="card" id="team-card">
          <div className="card-img" id="profile-img">
          <img src="https://via.placeholder.com/150?text=?" alt="Team member" />
          </div>
          <div className="desc" id="profile-desc">
            <h5 className="mentor-name">Tech Expert</h5>
            <h5 className="mentor-skills">This could be you!</h5>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSePtWrUUjf8WqOPo5V3pzUcNA5U_LeDkjDvMqVa6hhIQzvtkg/viewform" target="_blank"> Connect ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}></i> </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
