import React from 'react';
import '../styles/challenges.css'

const Challenges = () => {
  return (
    <div className="main-container">
    <div className="heading">
        <h2 style={{color:'#a78bfa'}} className="heading__title">Challenges with <span style={{color:'#00fa9a'}}>Traditional</span> way's !</h2>
      </div>
    <div className="cards" id="challengescard">
      <div className="card" id="challenges">No personalized attention. 😥</div>
      <div className="card" id="challenges">Limited resources & funding. 💰</div>
      <div className="card" id="challenges">Time constraints & deadlines. ⏰</div>
      <div className="card" id="challenges">Technical complexities. 🤯</div>
      <div className="card" id="challenges">Communication barriers. 😖</div>
      <div className="card" id="challenges">Isolating culture. 🔄</div>
      <div className="card" id="challenges">Team conflicts & collaboration issues. 💔</div>
      <div className="card" id="challenges">Theoretical Approach. 🤔</div>
    </div>
    <div class="center-wrapper">
      <br /><div id="glow-line" className="line"> </div><br />
      </div>
    </div>
  );
};

export default Challenges;
