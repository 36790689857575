import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import './App.css';
import Navbar from './components/navbar';
import ServicesSection from './components/services';
import Hero from './components/hero';
import Whoweare from './components/whoarewe';
import Contact from './components/contact';
import Jobhub from './components/jobhub';
import Training from './components/training';
import Terms from './components/TermsConditions';
import Joinus from './components/joinus'
import Team from './components/team';
import Solution from './components/solution';
import Challenges from './components/challenges1';
import Footer from './components/footer';
import Wwr from './components/wwr'
import Recruiter from './components/Recruiter';
import Mern from './components/mern';
import FrontendDeveloper from './components/frontend';
import BackendDeveloper from './components/backend';
import Devops from './components/devops';
import Cloud from './components/cloud';
import Socialmediamanager from './components/smm'
import FullStackDeveloper from './components/fsd';
import Projectmanager from './components/projectmanager';

const App = () => {
  return (
    <Router>
      <div>
        <Helmet>
          <title>Kalpaz Technologies</title>
          <meta
            name="description"
            content="Your description here"
          />
          <style>{'body { background-color: #101418; }'}</style>
        </Helmet>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeWithSections />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/jobhub" element={<Jobhub />} />
          <Route path="/training" element={<Training />} />
          <Route path="/termsconditions" element={<Terms />} />
          <Route path="/recruiter" element={<Recruiter />}/>
          <Route path='/mern' element={<Mern />} />
          <Route path='/Frontend' element={<FrontendDeveloper />} />
          <Route path='/Backend'element={<BackendDeveloper />}/> 
          <Route path='/Devops'element={<Devops/>}/>
          <Route path='/Cloud' element={<Cloud/>} />
          <Route path='/Socialmediamanager' element={<Socialmediamanager/>} />
          <Route path='/Fullstack' element={<FullStackDeveloper/>} />
          <Route path='/Projectmanager' element={<Projectmanager/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

const HomeWithSections = () => {
  return (
    <div>
      <Helmet>
      <style>{'body { background-color: #101418; }'}</style>
      </Helmet>
      <Hero />
      <ServicesSection />
      <Challenges />
      <Solution />
      <Team />
      <Joinus />
      
      <Whoweare />

      <Navbar />
    </div>
  );
};

export default App;
