import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import logo from '../assets/kalpaz-favicon-color.png';
import '../styles/recruiter.css';

const FrontendDeveloper = () => {
  return (
    <div className="container" style={{ marginTop: '80px' }}>
      <div className="row">
        {/* First Section (two-thirds of the page) */}
        <div className="col-md-8">
          <div className="overview card" style={{ position: 'relative', marginBottom: '20px' }}>
            <div className="card-body">
              {/* Company Logo in the top right corner */}
              <svg width="100" height="100" style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <rect width="60" height="60" x="10" y="10" rx="10" ry="10" stroke="rgba(255, 255, 255, .25)" strokeWidth="2" fill="none" />
        <image href={logo} x="10" y="10" width="60" height="60" alt="Company Logo"/>
      </svg>
              <h5 className="card-title">Frontend Developer</h5>
              <div className="row">
                <div className="col-auto">
                  <p className="card-text">
                    <i className="fas fa-briefcase" style={{ color: 'gray' }}></i> 2-5 years
                  </p>
                </div>
                <div className="col-auto">
                  <p className="card-text">
                    <span role="img" aria-label="Package">₹</span> Not disclosed
                  </p>
                </div>
              </div>
              <div>
                <p className="card-text">
                  <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                </p>
                {/* Horizontal Line */}
                <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
                {/* Button */}
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary">Apply →</button>
                </div>
              </div>
            </div>
          </div>

          {/* Job Description Card */}
          <div className="job-description card">
            <div className="card-body">
              <h5 className="card-title">Job Description</h5>
              <p className="card-text">
                <strong>Frontend Developer | 2-5 years | Remote</strong>
              </p>
              <h6>Responsibilities:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li> Develop new features that are visible to an end-user with the help of React.js and other front-end technologies;</li>
                <li> Create reusable components and front-end libraries to be applied in the future;</li>
                <li> Develop and maintain high quality, well tested, and performant UI/UX code</li>
                <li> Optimize components for their ultimate performance on a wide array of web-capable devices and browsers.</li>
                <li> Collaborate with back-end developers and web designers to improve usability.</li>
                <li> Collaborate with cross functional teams including product managers, UX designers, and backend engineers to ensure successful delivery of projects </li>
                <li> Participate in code reviews and maintain coding standards </li>
                <li> Keep current with the new emerging trends and technologies in front-end development.</li>
                <li>Stay updated with latest frontend technology trends and Identify areas where existing code can be optimized and suggest improvements</li>
              </ul>
              <h6>Role Details:</h6>
              <p>
                <strong>Role:</strong> Frontend Developer<br />
                <strong>Industry Type:</strong> IT Service and Consulting<br />
                <strong>Department:</strong> Development<br />
                <strong>Employment Type:</strong> Full Time, Permanent<br />
                <strong>Role Category:</strong> Development
              </p>
              <h6>Education:</h6>
              <p>
                <strong>UG:</strong> B.Tech/BE in Computer Science or related field<br />
                <strong>PG:</strong> Optional
              </p>
              <h6>Key Skills:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Proficiency in HTML, CSS, and JavaScript</li>
                <li>Strong experience with React.js and its core principles</li>
                <li>Experience with RESTful APIs</li>
                <li>Understanding of modern build pipelines and tools</li>
                <li>Experience with version control tools (e.g., Git)</li>
                <li>Strong problem-solving skills and attention to detail</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Second Section (one-third of the page) */}
        <div className="col-md-4 sidebar">
          <h5>Jobs you might be interested in</h5>
          
          {/* Full Stack Developer Card (Clickable) */}
          <Link to="/Fullstack" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Full Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Frontend Developer Card (Clickable) */}
          <Link to="/Frontend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Frontend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Backend Developer Card (Clickable) */}
          <Link to="/Backend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Backend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* MERN Stack Developer Card (Clickable) */}
          <Link to="/mern" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">MERN Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* DevOps Engineer Card (Clickable) */}
          <Link to="/Devops" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">DevOps Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Cloud Engineer Card (Clickable) */}
          <Link to="/Cloud" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Cloud Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Project Manager Card (Clickable) */}
          <Link to="/projectmanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Project Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/Socialmediamanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Social Media Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/recruiter" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Technical Recruiter</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
        </div>
        </div>
    </div>
  );
};

export default FrontendDeveloper;
