import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/career.css';
import PostJob from './postjob';
import Gettrained from './gettrained'
import '@fortawesome/fontawesome-free/css/all.css';
import logo from '../assets/kalpaz-favicon-color.png'

class CareerComponent extends Component {
  render() {
    return (
      
      <div>
        <div className="custom-background" >
        <div className="jumbotron bg-color">
          <div className="header">
            <h1>Your next dream Job</h1>
            <p className="header">
              Explore futuristic technology jobs and much more.
            </p>
          </div>
        </div>
        <div className="card" id="getupdate">
          🔔 Get updates of new job opening regularly! 🚀 
        </div>
        <div className="container" id="jobcontainer">
          <div className="row">
            {/* Card 1 */}
            <div className="col-sm-4 mb-3">
            <Link to="/recruiter" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)" strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Technical Recruiter</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 2 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Frontend" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Frontend Developer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 3 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Backend" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Backend Developer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 4 */}
            <div className="col-sm-4 mb-3">
            <Link to="/mern" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">MERN Stack Developer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 5 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Devops" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">DevOps Engineer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 6 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Cloud" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Cloud Engineer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            <div>
            <Gettrained />
            <div style={{padding:'80px'}}></div>
            </div>

            {/* Card 7 */}
            <div className="col-sm-4 mb-3">
            <Link to="/projectmanager" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Project Manager</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="/projectmanager" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 8 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Socialmediamanager" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Social Media Manager</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>

            {/* Card 9 */}
            <div className="col-sm-4 mb-3">
            <Link to="/Fullstack" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div id="jobcard">
                <div className="card-body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <svg width="60" height="60">
                      <rect width="50" height="50" x="8" y="5" rx="5" ry="5" stroke="rgba(255, 255, 255, .25)"  strokeWidth="1" fill="none" />
                      <image href={logo} x="8" y="5" width="50" height="50" />
                    </svg>
                    <span style={{ marginLeft: '10px', fontWeight:'bold'}}>Kalpaz</span>
                  </div>
                  <h6 className="card-title">Full Stack Developer</h6>
                  <ul>
                    <li>📍 Remote</li>
                    <li>🕒 Full Time</li>
                  </ul>
                  <a href="#" > ⟶ <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
                </div>
              </div>
              </Link>
            </div>
            <div>
            < PostJob />
            <div style={{padding:'80px'}}></div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CareerComponent;