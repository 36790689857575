import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/kalpaz-high-resolution-logo-transparent (1).png';
import '../styles/footer.css';

const logoStyle = {
  width: '120px',
  height: 'auto',
  padding: '20px',
  cursor: 'pointer',
};

function AppAppBar() {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Set initial mobile state

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Check viewport width on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          background: 'linear-gradient(to bottom, #181424, #0D1F31)',
          backdropFilter: 'blur(24px)',
          zIndex: '1000',
          paddingLeft: isMobile ? '0' : '80px', // Apply padding for laptop view
          paddingRight: isMobile ? '0' : '80px', // Apply padding for laptop view
        }}
      >
        <div style={{ maxWidth: 'lg', margin: '0 auto' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: '999px',
              width: '100%', // Ensure it spans full width including padding
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/">
                <img
                  src={logo}
                  style={logoStyle}
                  alt="Logo of Kalpaz Technologies"
                />
              </Link>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex' }}>
                {/* Conditionally render buttons based on mobile view */}
                {!isMobile && (
                  <>
                    <Link to="/jobhub" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <button style={{ padding: '6px 12px', cursor: 'pointer', border: 'none', background: 'transparent', outline: 'none', color: '#96A1B0' }}>
                        Job Hub
                      </button>
                    </Link>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScRKp0eRe_qXHfgRQyRjvTSe96TQnEZfzfEmPPcjv5lXNdIGw/viewform" style={{ textDecoration: 'none', color: 'inherit', paddingRight:'10px'}} target="_blank" rel="noopener noreferrer">
                      <button style={{ padding: '6px 12px', cursor: 'pointer', border: 'none', background: 'transparent', outline: 'none', color: '#96A1B0' }}>
                        Contact
                      </button>
                    </a>
                  </>
                )}
              </div>
              <div style={{ display: isMobile ? 'block' : 'none' }}> {/* Conditionally render based on mobile view */}
                {/* Hamburger Icon */}
                <button
                  onClick={toggleDrawer(true)}
                  style={{ padding: '20px', cursor: 'pointer', border: 'none', background: 'transparent', outline: 'none' }}
                >
                  <div style={{ width: '30px', height: '2px', backgroundColor: 'black', marginBottom: '6px' }} />
                  <div style={{ width: '30px', height: '2px', backgroundColor: 'black', marginBottom: '6px' }} />
                  <div style={{ width: '30px', height: '2px', backgroundColor: 'black' }} />
                </button>
              </div>
              {/* Sidebar menu */}
              {open && (
                <div
                  ref={sidebarRef}
                  style={{
                    background: 'linear-gradient(to bottom, #181424, #0D1F31)',
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    height: '100vh',
                    width: '60vw',
                    maxWidth: '80vw',
                    padding: '16px',
                    backgroundColor: '#233044',
                    zIndex: '1100',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(24px)',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Link to="/jobhub" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <button style={{ padding: '6px 12px', cursor: 'pointer', border: 'none', background: 'transparent', color: '#96A1B0' }}>
                        Job Hub
                      </button>
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <button style={{ padding: '6px 12px', cursor: 'pointer', border: 'none', background: 'transparent', color: '#96A1B0' }}>
                        Contact
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppAppBar;
