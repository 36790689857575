import React from 'react';
import '../styles/Whoweare.css';

function Whoweare() {
  return (
    <section id="whoweare" className="glow-bg" style={{background: 'linear-gradient(180deg,#101418, #111A2B)'}}>
      <div className="row dataTxt">
        <div className="col-md-4 col-sm-6 col-lg-4 col-xs-12">
          <h4>WHO WE ARE?</h4>
          <p>
            Kalpaz specializes in guiding organizations through today's
            corporate challenges, offering comprehensive technology solutions
            and support.
          </p>
          <p>
            From software consulting to staffing solutions, we've got the
            expertise to handle it all. Our mission is to empower organizations
            to thrive in the digital age by equipping them with the knowledge,
            skills, and tools they need to succeed.
          </p>
          <br />
        </div>
        <div className="col-md-4 col-sm-6 col-lg-4 col-xs-12">
          <h4>MISSION & VALUES</h4>
          <p>Discover our dedication to excellence and partnership. Our vision extends beyond mere transactions; where innovation flourishes, and aspirations are nurtured.</p>
          
          <ul className="list3">
            <li>Excellence is our goal.</li>
            <li>We value honesty and integrity.</li>
            <li>Innovation drives positive change for us.</li>
            <li>Collaboration achieves our goals.</li>
            
          </ul>
        </div>
        <div className="col-md-4 col-sm-6 col-lg-4 col-xs-12">
          <h4>WHY CHOOSE US?</h4>
          <p>
            We provide dedicated support to ensure smooth implementation and
            ongoing assistance for your needs.{' '}
          </p>
          <ul className="list3">
            <li>Customized solutions </li>
            <li>Dedicated support by experienced team.</li>
            <li>Client-focused processes </li>
            <li>Innovative approach</li>
            <li>Seamless implementation and management</li>
          </ul>
        </div>
        
      </div>
    </section>
    
  );
}

export default Whoweare;
