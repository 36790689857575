import React from 'react'
import '../styles/services1.css'

function Services() {
  return (
    <div className="service-container">
      <div className="box" id="service">
        <div className="content">
          <h2>01</h2>
          <h3>Software Services</h3>
          <p>
          Improve performance, cut costs, and limit risks with our consulting.
          </p>
        
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdfSfsmHMNAzr2hWiMRctPAJIMfT_AwMvHV5uXQJUOhpmeZ7g/viewform" target="_blank">Enquire →</a>
        </div>
      </div>
      <div className="box" id="service">
        <div className="content">
          <h2>02</h2>
          <h3>Brand Building</h3>
          <p>
          Enhance your brand with our specialized support with digital marketing.
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdSiANrefR7DGwVHmrGD7ExrApyTs8L22pmng7DATcEUdjWtg/viewform" target="_blank">Enquire →</a>
        </div>
      </div>
      <div className="box" id="service"> 
        <div className="content">
          <h2>03</h2>
          <h3>Staffing Solutions</h3>
          <p>
          Develop a team of skilled individuals with the expertise you seek.
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfFhAHstt9lLoR_nwZIFEptJpsCUTjYMv9goAn7d2SzV03fAA/viewform" target="_blank">Enquire →</a>
        </div>
      </div>
      <div className="box" id="service">
        <div className="content">
          <h2>04</h2>
          <h3>Corporate Training</h3>
          <p style={{marginTop:'20px'}}>
          Empower your team with essential skills and tech knowledge.
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKRRfQgREdxU-vFcWOjZVhZgVp5O8nrYYZpSnDRQ5dIkR8cw/viewform" target="_blank">Enquire →</a>
        </div>
      </div>
    </div>
  )
}

export default Services