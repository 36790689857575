import React from 'react';
import img1 from '../assets/card18.png';
import '../styles/career.css';

function GetTrained() {
  return (
    <div className="training d-flex justify-content-center">
      <div className="card w-75 mb-3" id="trainingcard">
        <div className="row g-0 align-items-center" style={{ height: '100%' }}>
          <div className="col-md-4 img-container">
            <img src={img1} className="img-fluid rounded-start img-small-on-mobile" alt="..." />
          </div>
          <div className="col-md-8 content-container">
            <div className="card-body">
              <h1 className="h1-small-on-mobile">Develop Tech Skills</h1>
              <p className="card-text p-small-on-mobile"><small className="text-body-secondary">Looking to bring talent onboard? Post a job and find the right fit for your team.</small></p>
              <a href="#" className="btn btn-primary btn-small-on-mobile">Learn More →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetTrained;
