import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import logo from '../assets/kalpaz-favicon-color.png';
import '../styles/recruiter.css';

const Devops = () => {
  return (
    <div className="container" style={{ marginTop: '80px' }}>
      <div className="row">
        {/* First Section (two-thirds of the page) */}
        <div className="col-md-8">
          <div className="overview card" style={{ position: 'relative', marginBottom: '20px' }}>
            <div className="card-body">
              {/* Company Logo in the top right corner */}
              <svg width="100" height="100" style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <rect width="60" height="60" x="10" y="10" rx="10" ry="10" stroke="rgba(255, 255, 255, .25)" strokeWidth="2" fill="none" />
        <image href={logo} x="10" y="10" width="60" height="60" alt="Company Logo"/>
      </svg>
              <h5 className="card-title">DevOps Engineer</h5>
              <div className="row">
                <div className="col-auto">
                  <p className="card-text">
                    <i className="fas fa-briefcase" style={{ color: 'gray' }}></i> 2-5 years
                  </p>
                </div>
                <div className="col-auto">
                  <p className="card-text">
                    <span role="img" aria-label="Package">₹</span> Competitive
                  </p>
                </div>
              </div>
              <div>
                <p className="card-text">
                  <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                </p>
                {/* Horizontal Line */}
                <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
                {/* Button */}
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary">Apply →</button>
                </div>
              </div>
            </div>
          </div>

          {/* Job Description Card */}
          <div className="job-description card">
            <div className="card-body">
              <h5 className="card-title">Job Description</h5>
              <p className="card-text">
                <strong>DevOps Engineer | 2-5 years | Remote</strong>
              </p>
              <h6>Responsibility:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Design, develop, and manage pipelines of CI/CD for the quality assurance of software releases.</li>
                <li>Automate provisioning, configuration, and deployment of infrastructure with Terraform, Ansible, or other similar tools.</li>
                <li>Keep an eye on system performance for issues troubleshooting in order to ensure high availability and reliability.</li>
                <li>Integrate development and operations workflows through development team collaboration.</li>
                <li>cloud infrastructure management and maintenance on platforms like AWS, Azure, or GCP</li>
                <li>Ensure security best practice across all areas of the infrastructure;</li>
                <li>Developing and maintaining documentation of the systems, processes, and procedures.</li>
                <li>Participation in on-call rotations for incident response and support.</li>
                <li>Continuously improve and optimize DevOps practices and tools.</li>
              </ul>
              <h6>Role Details:</h6>
              <p>
                <strong>Role:</strong> DevOps Engineer<br />
                <strong>Industry Type:</strong> IT Service and Consulting<br />
                <strong>Department:</strong> DevOps<br />
                <strong>Employment Type:</strong> Full Time, Permanent<br />
                <strong>Role Category:</strong> Engineering and Operations
              </p>
              <h6>Education:</h6>
              <p>
                <strong>UG:</strong> Any Graduate in Computer Science or related field<br />
                <strong>PG:</strong> Preferred but not mandatory
              </p>
              <h6>Key Skills:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Experience with CI/CD tools such as Jenkins, GitLab CI, or CircleCIAutomate infrastructure provisioning and configuration using Infrastructure as Code (IaC) tools.</li>
                <li>Implement and maintain monitoring and alerting systems for the infrastructure.</li>
                <li>Proficiency in scripting languages like Pytho or, Bash.</li>
                <li>Strong knowledge of cloud platforms such as AWS, Azure, or GCP.</li>
                <li>Good with containerization tools like Docker and orchestration tools like Kubernetes</li>
                <li>Understanding of infrastructure as code (IaC) principles</li>
                <li>Excellent problem-solving and troubleshooting skills</li>
                <li>Strong communication and collaboration abilities</li>
                <li>Automate deployment processes and ensure continuous integration and delivery.</li>
                <li>Required active participation/contribution in team discussions.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Second Section (one-third of the page) */}
        <div className="col-md-4 sidebar">
          <h5>Jobs you might be interested in</h5>
          
          {/* Full Stack Developer Card (Clickable) */}
          <Link to="/Fullstack" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Full Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Frontend Developer Card (Clickable) */}
          <Link to="/Frontend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Frontend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Backend Developer Card (Clickable) */}
          <Link to="/Backend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Backend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* MERN Stack Developer Card (Clickable) */}
          <Link to="/mern" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">MERN Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* DevOps Engineer Card (Clickable) */}
          <Link to="/Devops" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">DevOps Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Cloud Engineer Card (Clickable) */}
          <Link to="/Cloud" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Cloud Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Project Manager Card (Clickable) */}
          <Link to="/projectmanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Project Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/Socialmediamanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Social Media Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/recruiter" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Technical Recruiter</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
        </div>
        </div>
    </div>
  );
};

export default Devops;
