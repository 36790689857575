import React from 'react';
import '../styles/solution.css'

function Solution() {
  return (
    <div className="grey-bg container-fluid">
      <div className="heading">
        <h2 style={{color:'#a78bfa', textAlign:'center'}} className="heading__title">Kalpaz way to <span style={{color:'#00fa9a'}}>Address</span> the challenges !</h2>
      </div>
      <div className="row" >
        <div className="col-xl-3 col-sm-6 col-12"> 
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="align-self-center">
                    <i className="icon-pencil primary font-large-2 float-left"></i>
                  </div>
                  <div className="media-body text-right">
                    <h3>91.56 %</h3>
                    <span>Cost Efficiency</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="align-self-center">
                    <i className="icon-bubbles warning font-large-2 float-left"></i>
                  </div>
                  <div className="media-body text-right">
                    <h3>93.23 %</h3>
                    <span>Strong Connect</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="align-self-center">
                    <i className="icon-graph success font-large-2 float-left"></i>
                  </div>
                  <div className="media-body text-right">
                    <h3>83.00 %</h3>
                    <span>Swift Growth</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="align-self-center">
                    <i className="icon-pointer danger font-large-2 float-left"></i>
                  </div>
                  <div className="media-body text-right">
                    <h3>89.34</h3>
                    <span>Expert Insights</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="row">
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="danger">96.56 %</h3>
                    <span>Quick Support</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-rocket danger font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="success">120+</h3>
                    <span>Tech Expert</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-user success font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">80.43 %</h3>
                    <span>Conversion Rate</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-pie-chart warning font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 col-12">
          <div className="card" id="solution-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="primary">100 %</h3>
                    <span>Client First</span>
                  </div>
                  <div className="align-self-center">
                    <i className="icon-support primary font-large-2 float-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Solution;
