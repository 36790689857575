import React from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import logo from '../assets/kalpaz-favicon-color.png';
import '../styles/recruiter.css';

const BackendDeveloper = () => {
  return (
    <div className="container" style={{ marginTop: '80px' }}>
      <div className="row">
        {/* First Section (two-thirds of the page) */}
        <div className="col-md-8">
          <div className="overview card" style={{ position: 'relative', marginBottom: '20px' }}>
            <div className="card-body">
              {/* Company Logo in the top right corner */}
              <svg width="100" height="100" style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <rect width="60" height="60" x="10" y="10" rx="10" ry="10" stroke="rgba(255, 255, 255, .25)" strokeWidth="2" fill="none" />
        <image href={logo} x="10" y="10" width="60" height="60" alt="Company Logo"/>
      </svg>
              <h5 className="card-title">Backend Developer</h5>
              <div className="row">
                <div className="col-auto">
                  <p className="card-text">
                    <i className="fas fa-briefcase" style={{ color: 'gray' }}></i> 3-5 years
                  </p>
                </div>
                <div className="col-auto">
                  <p className="card-text">
                    <span role="img" aria-label="Package">₹</span> Not disclosed
                  </p>
                </div>
              </div>
              <div>
                <p className="card-text">
                  <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                </p>
                {/* Horizontal Line */}
                <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
                {/* Button */}
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary">Apply →</button>
                </div>
              </div>
            </div>
          </div>

          {/* Job Description Card */}
          <div className="job-description card">
            <div className="card-body">
              <h5 className="card-title">Job Description</h5>
              <p className="card-text">
                <strong>Backend Developer | 3-5 years | Remote</strong>
              </p>
              <h6>Responsibility:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Develop and maintain server-side components using Node.js and relevant frameworks.</li>
                <li>Write clean, efficient, and well-documented code.</li>
                <li>Implement new features and maintain existing ones.</li>
                <li>Collaborate with front-end developers to integrate user-facing elements with server-side logic.</li>
                <li>Work with databases, ensuring high performance and responsiveness to requests from the front-end.</li>
                <li>Design and implement RESTful APIs and microservices.</li>
                <li>Ensure API security, scalability, and maintainability.</li>
                <li>Identify and fix performance bottlenecks. </li>
                <li>Ensure the application is optimized for speed and scalability. </li>
                <li>Write unit tests and integration tests to ensure the robustness of the application.</li>
                <li>Work closely with other team members, including front-end developers, designers, and product managers, to achieve project goals.</li>
                <li>Participate in code reviews and provide constructive feedback to peers.</li>
                <li>Stay updated with the latest trends and best practices in Node.js development.</li>
                <li>Suggest and implement improvements to the development process.</li>
              </ul>
              <h6>Role Details:</h6>
              <p>
                <strong>Role:</strong> Backend Developer<br />
                <strong>Industry Type:</strong> IT Service and Consulting<br />
                <strong>Department:</strong> Engineering<br />
                <strong>Employment Type:</strong> Full Time, Permanent<br />
                <strong>Role Category:</strong> Software Development
              </p>
              <h6>Education:</h6>
              <p>
                <strong>UG:</strong> B.Tech/B.E. in Computer Science or related field<br />
                <strong>PG:</strong> M.Tech/M.S. in Computer Science or related field (preferred)
              </p>
              <h6>Key Skills:</h6>
              <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                <li>Proficiency in JavaScript and Node.js</li>
                <li>Experience with frameworks like Express.js, Koa.js, or similar.</li>
                <li>Strong understanding of asynchronous programming and event-driven architecture.</li>
                <li>Understanding of version control systems (e.g., Git)</li>
                <li>Databases knowledge such as MongoDB, MySQL, or PostgreSQL.</li>
                <li>Familiarity with RESTful APIs and microservices architecture.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Second Section (one-third of the page) */}
        <div className="col-md-4 sidebar">
          <h5>Jobs you might be interested in</h5>
          
          {/* Full Stack Developer Card (Clickable) */}
          <Link to="/Fullstack" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Full Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Frontend Developer Card (Clickable) */}
          <Link to="/Frontend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Frontend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Backend Developer Card (Clickable) */}
          <Link to="/Backend" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Backend Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* MERN Stack Developer Card (Clickable) */}
          <Link to="/mern" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">MERN Stack Developer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          
          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* DevOps Engineer Card (Clickable) */}
          <Link to="/Devops" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">DevOps Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Cloud Engineer Card (Clickable) */}
          <Link to="/Cloud" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Cloud Engineer</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>

          {/* Horizontal Line */}
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          
          {/* Project Manager Card (Clickable) */}
          <Link to="/projectmanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Project Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/Socialmediamanager" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Social Media Manager</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
          <Link to="/recruiter" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="card-body" style={{ cursor: 'pointer' }}>
              <h6 className="card-title">Technical Recruiter</h6>
              <p className="card-text">
                <i className="fas fa-map-marker-alt" style={{ color: 'gray' }}></i> Remote
                <a href="#" > <i style={{display: 'flex', justifyContent: 'flex-end' }}className="fas fa-share-alt"></i> </a>
              </p>
            </div>
          </Link>
          <hr style={{ margin: '20px 0', borderColor: 'gray', borderWidth: '1px' }} />
        </div>
        </div>
    </div>
  );
};

export default BackendDeveloper;
